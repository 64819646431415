:root {
    --bs-x: -0.5em;
    --bs-y: 0.5em;
    --bs-abs-x: max(var(--bs-x), -1 * var(--bs-x));
    --bs-abs-y: max(var(--bs-x), -1 * var(--bs-x));
    --base-color: #0000000;
    --dark-color: #FFFFFFF;
}

.btn {
    color: white;
    background-color: var(--base-color);
    cursor: pointer;
    border: none;
    padding: 0.6em 1.5em;
    margin: 0.7em 1em 0.7em 0.6em;
    position: relative;
    z-index: 1;
    box-shadow: var(--bs-x) var(--bs-y) var(--dark-color);
    transition: all 200ms ease-in-out;
}
.btn::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background-color: var(--dark-color);

    left: var(--bs-x);
    top: calc(var(--bs-y) - 0.2em);

    width: var(--bs-abs-x);
    height: calc(100%);
    transform: skewY(-45deg);
}

.btn::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background-color: var(--dark-color);

    top: calc(100%);
    right: calc(var(--bs-abs-x) - 0.25em);

    width: calc(100%);
    height: calc(var(--bs-abs-y));
    transform: skewX(-45deg);
}


.active:hover {
    transform: translate(0.5em, -0.5em);
    box-shadow: -1em 1em 0.15em rgba(16, 24, 50, 0.1);
}

.active:active {
    transform: translate(-0.2em, 0.2em);
    box-shadow: -0.5em 0.5em 0.15em rgba(16, 24, 50, 0.1);
}

.btn:disabled {
    background-color: color-mix(in srgb, var(--base-color) 50%, gray); /* Darken the shadow color */
    color: rgba(255, 255, 255, 0.7); /* Slightly fade the text color */
    cursor: not-allowed; /* Change the cursor to indicate the button is not clickable */
    box-shadow: none; /* Remove the box shadow */
    transform: none; /* Reset any transforms */
}

.btn:disabled:hover {
    transform: none; /* Ensure the button doesn't move on hover */
    box-shadow: none; /* Ensure no shadow on hover */
}

.btn:disabled::before,
.btn:disabled::after {
    background-color: color-mix(in srgb, var(--dark-color) 50%, gray); /* Darken the shadow color */
}