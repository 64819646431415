.input {
    border: 2px solid #FFFFFF;
    outline: none;
    background-color: transparent;
    padding: 15px;
    margin: 0;
    font-family: inherit;
    font-weight: 300;
    font-size: 16px;
    color: #FFFFFF;
}