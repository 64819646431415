:root {
    --rotateVal: 0deg;
    --blockWidth: 500;
    --blockHeight: 65px;
}

.container {
    position: relative;
}

.blockContainer {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    rotate: var(--rotateVal);
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
}


.block {
    width: var(--blockWidth);
    height: var(--blockHeight);
}