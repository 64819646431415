:root {
    --bs-x: -0.5em;
    --bs-y: 0.5em;
    --bs-abs-x: max(var(--bs-x), -1 * var(--bs-x));
    --bs-abs-y: max(var(--bs-x), -1 * var(--bs-x));
    --base-color: #0000000;
    --light-color: #FFFFFFF;
}


.paper {
    color: white;
    position: relative;
    z-index: 1;
    transition: all 200ms ease-in-out;
}
.paper.pattern {
    background-image: url('../../assets/website/bg_mc.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/*      Left element
       - insert element
       - position it correct left and top distance
       - skew it
       - sizing because normal relation + distortion
       */

.paper::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -2;
    background-color: var(--light-color);

    left: var(--bs-x);
    top: calc(var(--bs-y) - 0.2em);

    width: var(--bs-abs-x);
    height: calc(100%);
    transform: skewY(-45deg);
}

/*      Bottom element
       - insert element
       - position it correct left and top distance
       - skew it
       - sizing because normal relation + distortion
       !*/

.paper::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -2;
    background-color: var(--light-color);

    top: calc(100%);
    right: calc(var(--bs-abs-x) - 0.25em);

    width: calc(100%);
    height: calc(var(--bs-abs-y));
    transform: skewX(-45deg);
}

.colorLayer {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--base-color);
    opacity: 0.9; /* Anpassen, um die gewünschte Transparenz zu erreichen */
    z-index: -1;
}

.colorLayer2 {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--base-color);
    mix-blend-mode: hue;
    z-index: -1;
}