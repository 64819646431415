.container {
    min-height: 100vh;
    background: linear-gradient(180deg, #190918 0%, #4D0A3B 100%);
    padding-bottom: 120px;
    padding-top: 180px;
}

.liveLeaderboardSection {

}




.tabButton {

}


.sideMapButtons {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    margin-right: 50px;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    min-height: 70vh;
}

.leaderboardContainer {
    margin-top: 40px;
    width: 100%;
}

.peepoSprintText {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding-top: 35px;
    margin-bottom: 75px;
}

.currentTabIndicator {

}

.zaveContainer {
    display: flex;
    width: 100%;
}

.zaveItLogo {
    margin-left: auto;
    height: 50px;
}


@media (max-width: 900px) {
    .contentContainer {
        flex-direction: column;
        text-align: center;
    }

    .sideMapButtons {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
    }

    .leaderboardContainer {
        width: 100%;
    }

    .peepoSprintText {
        text-align: center;
    }

    .liveLeaderboardSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .zaveContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
    }

}
