.countdownSection {
    position: relative;
}

.countdownSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/website/home/coutndown_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layer2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    mix-blend-mode: multiply;
    opacity: 0.7;
}


.countdownWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 240px;
    padding-bottom: 240px;
    z-index: 2;

    /*top: 50%;*/
    /*left: 50%;*/
    /*z-index: 2;*/
    /*transform: translate(-50%, -50%);*/
}

.qualifierPaper {
    max-width: 1000px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    padding: 20px 20px;
}

.gridContainer {
    margin-top: 40px;
    display: flex;
    flex-wrap: nowrap;
    gap: 50px; /* Adjust as needed */
    @media (max-width: 700px) {
        flex-wrap: wrap;
    }
}

.gridItem {
    flex: 1 1 100%; /* Default to full width for smaller screens */

    /* Adjust as needed for larger screens */
    @media (min-width: 600px) {
        flex: 1 1 33%; /* One-third width for medium screens and up */
    }
}

.gridIcon {
    height: 75px;
    width: auto;
}

.gridText {
    text-wrap: nowrap;
}


.explainList li {
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 40px;
}

@media (max-width: 900px) {
    .countdownSection::before {
        background-size: cover;
    }

}
