.rulesSection {
    background-color: var(--bgDark);
    padding-bottom: 170px;
    @media (max-width: 900px) {
        text-align: center;
    }
}

.rulesTitle {

}

.rulesSubtitle {
    max-width: 50%;
    @media (max-width: 900px) {
        text-align: center;
        max-width: unset;
    }
}

.rulesContent {
    margin-top: 50px;
}
