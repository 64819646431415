.container {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 100%;
    padding-top: 40px;
}

@media (max-width: 900px) {
    .container {
        text-align: center;
    }
}
