@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/website/fonts/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/website/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/website/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/website/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/website/fonts/Montserrat-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Belfast Grotesk';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/website/fonts/BelfastGrotesk-Black.ttf') format('truetype');
}


:root {
    --primary: #00AE7B;
    --primary-light: #23F6CD;
    --primary-dark: #00332A;

    --secondary-light: #703A62;
    --secondary: #4D0A3B;
    --secondary-dark: #190918;

    --bgLight: #f2f2f2;
    --bgDark: #0E121A;
    --bg: #253042;
    --error: #690a0a;

    --gradientPrimary: linear-gradient(90deg, #00332A 0%, #00AE7B 100%);
    --gradientSecondary: linear-gradient(90deg, #190918 0%, #4D0A3B 100%);

    --small-screen-width: 900px;
}

body {
    font-family: 'Montserrat', 'sans-serif';
    color: white;
    font-style: normal;
    min-height: 80vh;
    background-color: var(--bgDark);
}

body, footer {
    cursor: url('./assets/website/cursor.png') 31.22 31.22, auto;
}


h1 {
    font-weight: 700;
    font-size: 58px;
    text-transform: uppercase;
    margin: 5px 32px 32px;
    @media (max-width: 900px) {
        font-size: 2.4rem;
    }
}

h2 {
    font-weight: 700;
    font-size: 42px;
    text-transform: uppercase;
    margin-left: 0;
    margin-bottom: 8px;
    margin-top: 5px;
}

h3 {
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
}

h4 {
    font-weight: 700;
    font-size: 21px;
    margin: 12px;
}

h5 {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
}

button {
    font-size: 16px;
    font-weight: 700;
}

p {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
}

span {

}


.page-wrapper {
    max-width: 1200px;
}

.section {
    padding-top: 120px;
    padding-bottom: 60px;
}

.center {
    margin: auto
}

.clickable {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: white;
}


