.section {
    min-height: 100vh;
    background: linear-gradient(180deg, #190918 0%, #4D0A3B 100%);
    padding-bottom: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/website/home/coutndown_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.formWrapper {
    text-align: center;
    padding: 20px 40px;
    max-width: 800px;
}

.formWrapperError {
    text-align: center;
    padding: 20px 40px;
    max-width: 500px;
}
