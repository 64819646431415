.tabsHeader {
    display: flex;
    flex-direction: row;
    @media (max-width: 900px) {
        flex-direction: column;
        text-align: center;
    }
}

.tabsContent {
    margin-top: 70px;
}

.tabContent {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with equal width */
    gap: 20px; /* Spacing between grid items */
    @media (max-width: 900px) {
        grid-template-columns: 1fr; /* One column */
    }
}

.tabContent p {
    margin-top: 10px;
}
