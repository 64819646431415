.footer {
    height: 75px;
    position: relative;
    background-color: var(--bg);
}

.footer::after {
    content: "";
    position: absolute;
    top: -60px;
    left: auto;
    width: 100%;
    overflow: hidden;
    height: 65px;
    background-image: url('../../assets/website/layout/footer_squares.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    z-index: 100;
}

.footerContainer {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerText {
    font-weight: 400;
    font-size: 18px;
    color: white;
}

.twitchText {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.twitchText img {
    padding: 7px;
    margin-right: 5px;
    background-color: var(--primary);
}

.middleContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.copyRightText {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    color: #ACACAC;
    right: 0;

    @media (max-width: 900px) {
       text-align: center;
    }
}

.buildNumber {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: lightgray;
    font-size: small;
}


@media (max-width: 900px) {
    .footer {
        padding-top: 20px;
        height: unset;
        padding-bottom: 20px;
    }

    .footerContainer {
        flex-direction: column;
        gap: 30px
    }

    .middleContainer {
        position: unset;
        transform: unset;
    }
}
