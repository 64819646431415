.fixedNavbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
    pointer-events: none;
}

.fixedNavbarLogo {
    height: 100px;
    width: auto;
    pointer-events: all;
}

.fixedNavbarItemContainer {
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    pointer-events: all;
}

.fixedNavbarItem {
    margin-left: 32px;
    position: relative;
    cursor: pointer;
    pointer-events: all;
}

.navbarLink {
    text-decoration: none;
    color: white;
    pointer-events: all;
}

.navbarLink.active {
    padding: 1px 5px;
    border: 1px solid #FFFFFF;
}


.fixedNavbarItem.live:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -0.5em;
    transform: translateY(-50%) translateX(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--secondary); /* Ersetze "secondary" durch die gewünschte Farbe */
    animation: pulsate 3s infinite;
}

@keyframes pulsate {
    0%, 100% {
        background-color: var(--secondary);
    }
    50% {
        background-color: var(--secondary-dark); /* Helle Version der Farbe */
    }
}
