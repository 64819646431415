.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.successMessage {
    color: green;
    margin-top: 20px;
    margin-bottom: 20px;

}


.inputSpacing {
    margin-top: 20px;
    margin-bottom: 20px;
}


.submitButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkboxLabel {
    margin-top: 20px;
    margin-bottom: 20px;
}
