.faqSection {
    padding-top: 140px;
    background: linear-gradient(180deg, #4D0A3B 0%, #190918 100%);
    @media (max-width: 900px) {
        text-align: center;
    }
}

.faqText {
    font-weight: 400;
    font-size: 18px;
    color: #B5B5B5;
    max-width: 70%;
}

.accordion {
    padding: 10px 10px 10px 0;
}


