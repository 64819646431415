.accordion {
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.open {
    height: auto;
}

.accordionHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 10px 10px 0;
    border-bottom: 0.5px solid #575757;
}

.accordionHeader h4 {
    flex-grow: 1;
    margin: 0;
}

.accordionIcon {
    font-weight: bold;
    margin-left: 10px;
}

.accordionContent {
    padding: 10px;
}
